import "core-js/modules/es.array.push.js";
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./style.css";
// import required modules
import { Navigation } from "swiper";
export default defineComponent({
  name: "HomeView",
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const descArr = ref([{
      icon: require("@/assets/image/home/mspc_ic_t.png"),
      desc: "添加一些描述，你希望画面中出现的或者不希望画面中出现的，喵手AI会根据你的描述智能生成作品。",
      titleIcon: require("@/assets/image/home/mspc_model_1.png"),
      imgUrl: require("@/assets/image/home/mspc_pic_01.png"),
      btnLabel: "前去试试",
      url: "textToImg"
    }, {
      icon: require("@/assets/image/home/mspc_ic_photo.png"),
      desc: "上传一张图片，可以选择添加一些描述，或者直接生成，喵手AI会根据你的图片智能生成作品。",
      titleIcon: require("@/assets/image/home/mspc_model_2.png"),
      imgUrl: require("@/assets/image/home/mspc_pic_02.png"),
      btnLabel: "前去试试",
      url: "imgToImg"
    }, {
      icon: require("@/assets/image/home/mspc_ic_local.png"),
      desc: "喵手AI会生成一个遮盖层，你可以使用画笔来涂抹需要修改的区域，然后只修改涂抹区域。",
      titleIcon: require("@/assets/image/home/mspc_model_3.png"),
      imgUrl: require("@/assets/image/home/mspc_pic_03.png"),
      btnLabel: "前去试试",
      url: "areaToImg"
    }, {
      icon: require("@/assets/image/home/mspc_ic_enlarge.png"),
      desc: "上传一张图片，通过AI算法来提高图片分别率。",
      titleIcon: require("@/assets/image/home/mspc_model_4.png"),
      imgUrl: require("@/assets/image/home/mspc_pic_04.png"),
      btnLabel: "前去试试",
      url: "ratioToImg"
    }, {
      icon: require("@/assets/image/home/mspc_ic_edit.png"),
      desc: "通过对作品添加或修改文字描述的方式，来改变图片的内容。",
      titleIcon: require("@/assets/image/home/mspc_model_5.png"),
      imgUrl: require("@/assets/image/home/mspc_pic_05.png"),
      btnLabel: "前去试试",
      url: "textUpdateImg"
    }, {
      icon: require("@/assets/image/home/mspc_ic_model.png"),
      desc: "通过提供参考图片来生成与对应controlnet模型相应的控制图片。",
      titleIcon: require("@/assets/image/home/mspc_model_7.png"),
      imgUrl: require("@/assets/image/home/mspc_pic_06.png"),
      btnLabel: "前去试试",
      url: "controlnet"
    }]);
    const previewCode = ref(`{
	"apiKey": "",
	"batch_size": "1",
	"height": 512,
	"width": 512,
	"model_id": "moxin1",
	"prompt": "1girl",
	"negative_prompt": "",
	"num_inference_steps": 20,
	"samplers": "EulerAncestralDiscreteScheduler",
	"seed": 0,
	"enhance_prompt": "no",
	"cfg_scale": 10
}`);
    const modules = [Navigation];
    let tempArr = [];
    for (let i = 1; i <= 9; i++) {
      tempArr.push({
        modelUrl: "https://pub-8b49af329fae499aa563997f5d4068a4.r2.dev/generations/4597582481676065923.png",
        modelId: "cyberpunk-anime-diff",
        modelTitle: "Cyberpunk-Anime-Diffusion",
        modelAuthor: "kyon",
        modelDesc: "Cyberpunk Anime Diffusion An AI model that generates cyberpunk anime characters!~ Based of a finetuned Waifu Diffusion V...",
        modelStar: 3
      });
    }
    const modelsArr = ref(tempArr);
    const modelBgArr = ref([[require("@/assets/image/home/mspc_pic_07.png"), require("@/assets/image/home/mspc_pic_08.png"), require("@/assets/image/home/mspc_pic_09.png")], [require("@/assets/image/home/mspc_pic_10.png"), require("@/assets/image/home/mspc_pic_11.png")], [require("@/assets/image/home/mspc_pic_12.png"), require("@/assets/image/home/mspc_pic_13.png")], [require("@/assets/image/home/mspc_pic_14.png")], [require("@/assets/image/home/mspc_pic_15.png"), require("@/assets/image/home/mspc_pic_16.png")], [require("@/assets/image/home/mspc_pic_17.png"), require("@/assets/image/home/mspc_pic_18.png")], [require("@/assets/image/home/mspc_pic_19.png"), require("@/assets/image/home/mspc_pic_20.png"), require("@/assets/image/home/mspc_pic_21.png")]]);
    const circleArr = ref([3, 2, 2, 1, 2, 2, 3]);
    const qaArr = ref([]);
    const showAnswer = index => {
      qaArr.value[index]["isShow"] = !qaArr.value[index]["isShow"];
    };
    onMounted(() => {
      get("/config/faq/list?pageNo=0&pageSize=10").then(res => {
        let {
          list
        } = res.data;
        console.log(list, "list");
        qaArr.value = list.map(item => {
          item["isShow"] = false;
          return item;
        });
      }).catch(rej => {
        console.log(rej, "rej");
      });
    });
    const jumpUrl = () => {
      window.open('http://www.liandange.com/', '_blank');
    };
    const jumpUrl2 = () => {
      window.open('/imgDev/textToImg', '_blank');
    };
    return {
      descArr,
      previewCode,
      modules,
      modelsArr,
      modelBgArr,
      circleArr,
      qaArr,
      showAnswer,
      jumpUrl,
      jumpUrl2
    };
  }
});