import "core-js/modules/es.array.push.js";
import LoginDialog from "./LoginDialog.vue";
export default defineComponent({
  name: "MyHeader",
  props: {
    msg: String
  },
  components: {
    LoginDialog
  },
  setup(props, {
    emit
  }) {
    const routerArr = ref([{
      title: "主页",
      url: "/",
      name: "home"
    }, {
      title: "控制台",
      url: "/controller/overView",
      name: "overView"
    }, {
      title: "试验田",
      url: "",
      tips: "H"
    }, {
      title: "模型",
      url: "/models",
      name: "models",
      tips: "N"
    }, {
      title: "API文档",
      url: "/apidoc/basicApiDoc",
      name: "apiDoc"
    }]);
    const routerList = ref([{
      title: "概述",
      url: "overView",
      iconUrl: require("@/assets/image/header/mspc_ic_overview.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_overview_white.png")
    }, {
      title: "API设置",
      url: "apiSetting",
      iconUrl: require("@/assets/image/header/mspc_ic_api.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_api_white.png")
    }, {
      title: "调用历史",
      url: "callHistory",
      iconUrl: require("@/assets/image/header/mspc_ic_history.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_history_white.png")
    },
    // {
    //   title: "模型训练",
    //   url: "modelTrain",
    //   iconUrl: require("@/assets/image/header/mspc_ic_model.png"),
    //   iconUrl2: require("@/assets/image/header/mspc_ic_model_white.png"),
    // },
    {
      title: "套餐管理",
      url: "subManage",
      iconUrl: require("@/assets/image/header/mspc_ic_shopping.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_shopping_white.png")
    }, {
      title: "API文档",
      url: "apiDoc",
      iconUrl: require("@/assets/image/header/mspc_ic_log.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_log_white.png")
    }]);
    const routerList2 = ref([{
      title: "文字生成图片",
      url: "textToImg",
      iconUrl: require("@/assets/image/header/mspc_ic_t.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_t_white.png")
    }, {
      title: "图片生成图片",
      url: "imgToImg",
      iconUrl: require("@/assets/image/header/mspc_ic_photo.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_photo_white.png")
    }, {
      title: "局部生成",
      url: "areaToImg",
      iconUrl: require("@/assets/image/header/mspc_ic_local.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_local_white.png")
    }, {
      title: "分辨率提升",
      url: "ratioToImg",
      iconUrl: require("@/assets/image/header/mspc_ic_enlarge.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_enlarge_white.png")
    }, {
      title: "文字更改图片",
      url: "textUpdateImg",
      iconUrl: require("@/assets/image/header/mspc_ic_edit.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_edit_white.png")
    }, {
      title: "Controlnet",
      url: "controlnet",
      iconUrl: require("@/assets/image/header/mspc_ic_model.png"),
      iconUrl2: require("@/assets/image/header/mspc_ic_model_white.png")
    }]);
    const routerList3 = ref([{
      title: "提交工单",
      url: "workOrderApply"
    }, {
      title: "我的工单",
      url: "workOrderList"
    }]);
    const messageList = ref([{
      date: "2023-01-29",
      title: "云服务器104.249.172.37续费成功通知"
    }, {
      date: "2023-01-29",
      title: "云服务器104.249.172.37续费成功通知"
    }, {
      date: "2023-01-29",
      title: "云服务器104.249.172.37续费成功通知"
    }]);
    const clearInfo = () => {
      localStorage.clear();
      userInfo.value = {};
      location.reload();
    };
    const isDown = ref({
      1: false,
      2: false,
      6: false,
      7: false
    });
    const aiwebStore = useAiWeb();
    const isShow = computed(() => {
      return aiwebStore["loginInfo"]["loginFlag"];
    });
    const type = computed(() => {
      return aiwebStore["loginInfo"]["type"];
    });
    const mouseOver = index => {
      if (index != 1 && index != 2 && index != 7 && index != 6) return;
      isDown.value[index] = true;
    };
    const mouseleave = index => {
      if (index != 1 && index != 2 && index != 7 && index != 6) return;
      isDown.value[index] = false;
    };
    const toggleDialog = (_type, _isShow, value) => {
      aiwebStore["setLoginInfo"]({
        loginFlag: _isShow,
        type: _type
      });
      if (value) {
        userInfo.value = value;
      }
    };
    const route = useRoute();
    watch(() => route.name, value => {
      console.log(value, "value");
    });
    const routerName = computed(() => {
      return route.name;
    });
    const userInfo = ref({});
    onMounted(() => {
      if (localStorage.userInfo) {
        userInfo.value = JSON.parse(localStorage.userInfo);
      }
    });
    const router = useRouter();
    const handleCommand = command => {
      switch (command) {
        case "a":
          router.push(`/controller/accountSetting`);
          break;
        case "l":
          clearInfo();
          break;
      }
    };
    return {
      handleCommand,
      routerArr,
      mouseOver,
      mouseleave,
      isDown,
      routerList,
      routerList2,
      routerList3,
      isShow,
      type,
      toggleDialog,
      routerName,
      messageList,
      userInfo,
      clearInfo
    };
  }
});