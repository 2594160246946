/**
 *
 * @param {* number or new Date} time
 * @param {* string or undefiend} sign
 * @return // string yyyy-mm-dd
 */
export function formatNum(num) {
  if (num % 1 !== 0) {
    num = num.toFixed(2);
  }
  if (num < 100000) {
    return num.toString().replace(/(\d{1,3})(?=(\d{3})+$)/g, "$1,");
  } else {
    return (num / 1000).toFixed(1) + "K";
  }
}

export function formatTime(time, sign = "-") {
  if (!time) {
    return ''
  }
  if (typeof time !== "object") {
    time = new Date(time);
  }
  let month = time.getMonth() + 1;
  month = month >= 10 ? month : `0${month}`;
  let d = time.getDate();
  d = d >= 10 ? d : `0${d}`;
  return time.getFullYear() + sign + month + sign + d;
}

export function formatTime2(time, sign = "-") {
  if (!time) {
    return ''
  }
  if (typeof time !== "object") {
    time = new Date(time);
  }
  let month = time.getMonth() + 1;
  month = month >= 10 ? month : `0${month}`;
  let d = time.getDate();
  d = d >= 10 ? d : `0${d}`;
  let hour = time.getHours();
  hour = hour >= 10 ? hour : `0${hour}`;
  let minute = time.getMinutes();
  minute = minute >= 10 ? minute : `0${minute}`;
  let seconds = time.getSeconds();
  seconds = seconds >= 10 ? seconds : `0${seconds}`;
  return time.getFullYear() + sign + month + sign + d +' ' +`${hour}:${minute}:${seconds}`;
}


export function checkPhone(phoneNum) {
  if (!/^1[34578]\d{9}$/.test(phoneNum)) {
    return false;
  } else {
    return true;
  }
}

export function str(string, inx) {
  return string.substring(0, inx) + '...';
}

export function changeAppIdsToAppName(appList, appIds) {
  let AppNamesArr = [];
  appList.map(v => {
    appIds.map(vv => {
      if (v.appId === vv) {
        AppNamesArr.push(v.appName);
      }
    });
  });
  return AppNamesArr;
}

export const handlerTime = (dateTimeStamp) => {
  var now = new Date().getTime();
  var diffValue = now - dateTimeStamp; //时间差

  if (diffValue < 0) {
    return;
  }
  var result;
  let min = diffValue / 1000 / 60;
  let hour = min / 60;
  let day = hour / 24;
  let week = day / 7;
  let mon = day / 30;
  if (mon >= 1) {
    result = parseInt('' + mon) + '个月前';
  } else if (week >= 1) {
    result = parseInt('' + week) + '周前';
  } else if (day >= 1) {
    result = parseInt('' + day) + '天前';
  } else if (hour >= 1) {
    result = parseInt('' + hour) + '小时前';
  } else if (min >= 1) {
    result = parseInt('' + min) + '分钟前';
  } else {
    result = '刚刚';
  }
  return result;
};
export const handlerTime2 = (dateTimeStamp) => {
  var now = new Date().getTime();
  var diffValue = now - dateTimeStamp; //时间差

  if (diffValue < 0) {
    return;
  }
  var result;
  let min = diffValue / 1000 / 60;
  let hour = min / 60;
  // let day = hour / 24;
  // let week = day / 7;
  // let mon = day / 30;
  if (hour > 48) {
    result = formatTime(dateTimeStamp);
  } else if (hour >= 24 && hour <= 48) {
    result = '昨天';
  } else if (hour < 24 && hour >= 1) {
    result = parseInt('' + hour) + '小时前';
  } else if (min >= 1) {
    result = parseInt('' + min) + '分钟前';
  } else {
    result = '刚刚';
  }
  return result;
}
export const numHandler = function (num) {
  let resultNum = '';
  if (!num) {
    resultNum = 0;
  }
  if (num < 1000) {
    resultNum = num;
  } else if (num > 999 && num < 10000) {
    resultNum = (num / 1000).toFixed(1) + 'k';
  } else if (num > 9999 && num < 100000) {
    resultNum = (num / 10000).toFixed(1) + 'w';
  } else if (num > 99999) {
    resultNum = '10w+';
  }
  return resultNum;
};

export const imageslim = function (img) {
  if (img && img.indexOf("qiniu") != -1) {
    if (img.includes("imageMogr2")) {
      return img + "/auto-orient/interlace/1";
    }
    return img + "?imageMogr2/auto-orient/interlace/1";
  } else {
    return img;
  }
};