import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
const routes = [{
  path: "/",
  name: "home",
  component: HomeView
}, {
  path: "/about",
  name: "about",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */"../views/AboutView.vue")
}, {
  path: "/create",
  name: "create",
  component: () => import( /* webpackChunkName: "about" */"../views/ImgCreate.vue")
}, {
  path: "/canvas",
  name: "canvas",
  component: () => import( /* webpackChunkName: "about" */"../views/Canvas.vue")
}, {
  path: "/canvas2",
  name: "canvas2",
  component: () => import( /* webpackChunkName: "about" */"../views/Canvas2.vue")
}, {
  path: "/controller",
  name: "controller",
  component: () => import( /* webpackChunkName: "about" */"../views/controller/index.vue"),
  meta: {
    isCheck: true
  },
  children: [{
    path: "overView",
    name: "overView",
    component: () => import( /* webpackChunkName: "about" */"../views/controller/views/overView.vue")
  }, {
    path: "apiSetting",
    name: "apiSetting",
    component: () => import( /* webpackChunkName: "about" */"../views/controller/views/apiSetting.vue")
  }, {
    path: "callHistory",
    name: "callHistory",
    component: () => import( /* webpackChunkName: "about" */"../views/controller/views/callHistory.vue")
  }, {
    path: "modelTrain",
    name: "modelTrain",
    component: () => import( /* webpackChunkName: "about" */"../views/controller/views/modelTrain.vue")
  }, {
    path: "subManage",
    name: "subManage",
    component: () => import( /* webpackChunkName: "about" */"../views/controller/views/subManage.vue")
  }, {
    path: "accountSetting",
    name: "accountSetting",
    component: () => import( /* webpackChunkName: "about" */"../views/controller/views/accountSetting.vue")
  }, {
    path: "recommendManage",
    name: "recommendManage",
    component: () => import( /* webpackChunkName: "about" */"../views/controller/views/recommendManage.vue")
  }]
}, {
  path: "/apidoc",
  name: "apidoc",
  component: () => import( /* webpackChunkName: "about" */"../views/apidoc/index.vue"),
  meta: {
    isCheck: false
  },
  children: [{
    path: "basicApiDoc",
    name: "basicApiDoc",
    component: () => import( /* webpackChunkName: "about" */"../views/apidoc/views/basicApiDoc.vue")
  }, {
    path: "textToImgDoc",
    name: "textToImgDoc",
    component: () => import( /* webpackChunkName: "about" */"../views/apidoc/views/textToImgDoc.vue")
  }, {
    path: "imgToImgDoc",
    name: "imgToImgDoc",
    component: () => import( /* webpackChunkName: "about" */"../views/apidoc/views/imgToImgDoc.vue")
  }, {
    path: "areaToImgDoc",
    name: "areaToImgDoc",
    component: () => import( /* webpackChunkName: "about" */"../views/apidoc/views/areaToImgDoc.vue")
  }, {
    path: "ratioToImgDoc",
    name: "ratioToImgDoc",
    component: () => import( /* webpackChunkName: "about" */"../views/apidoc/views/ratioToImgDoc.vue")
  }, {
    path: "controlnetDoc",
    name: "controlnetDoc",
    component: () => import( /* webpackChunkName: "about" */"../views/apidoc/views/controlnetDoc.vue")
  }, {
    path: "textUpdateImgDoc",
    name: "textUpdateImgDoc",
    component: () => import( /* webpackChunkName: "about" */"../views/apidoc/views/textUpdateImgDoc.vue")
  }, {
    path: "FetchDoc",
    name: "FetchDoc",
    component: () => import( /* webpackChunkName: "about" */"../views/apidoc/views/FetchDoc.vue")
  }]
}, {
  path: "/models",
  name: "models",
  component: () => import( /* webpackChunkName: "about" */"../views/models/modelsIndex.vue")
}, {
  path: "/models/Detail",
  name: "modelsDetail",
  component: () => import( /* webpackChunkName: "about" */"../views/models/modelsDetail.vue")
}, {
  path: "/apidoc",
  name: "apiDoc",
  component: () => import( /* webpackChunkName: "about" */"../views/ApiDoc.vue")
}, {
  path: "/imgDev",
  name: "imgDev",
  meta: {
    isCheck: false
  },
  children: [{
    path: "textToImg",
    name: "textToImg",
    component: () => import( /* webpackChunkName: "about" */"../views/imgDev/views/textToImg.vue")
  }, {
    path: "imgToImg",
    name: "imgToImg",
    component: () => import( /* webpackChunkName: "about" */"../views/imgDev/views/imgToImg.vue")
  }, {
    path: "areaToImg",
    name: "areaToImg",
    component: () => import( /* webpackChunkName: "about" */"../views/imgDev/views/areaToImg.vue")
  }, {
    path: "ratioToImg",
    name: "ratioToImg",
    component: () => import( /* webpackChunkName: "about" */"../views/imgDev/views/ratioToImg.vue")
  }, {
    path: "controlnet",
    name: "controlnet",
    component: () => import( /* webpackChunkName: "about" */"../views/imgDev/views/controlnet.vue")
  }, {
    path: "textUpdateImg",
    name: "textUpdateImg",
    component: () => import( /* webpackChunkName: "about" */"../views/imgDev/views/textUpdateImg.vue")
  }, {
    path: "modelsTrain",
    name: "modelsTrain",
    component: () => import( /* webpackChunkName: "about" */"../views/imgDev/views/modelsTrain.vue")
  }]
}, {
  path: "/messageList",
  name: "messageList",
  meta: {
    isCheck: true
  },
  component: () => import( /* webpackChunkName: "about" */"../views/messageBox/messageList.vue")
}, {
  path: "/messageDetail",
  name: "messageDetail",
  meta: {
    isCheck: true
  },
  component: () => import( /* webpackChunkName: "about" */"../views/messageBox/messageDetail.vue")
}, {
  path: "/workOrderList",
  name: "workOrderList",
  meta: {
    isCheck: true
  },
  component: () => import( /* webpackChunkName: "about" */"../views/workOrderBox/workOrderList.vue")
}, {
  path: "/workOrderApply",
  name: "workOrderApply",
  meta: {
    isCheck: true
  },
  component: () => import( /* webpackChunkName: "about" */"../views/workOrderBox/workOrderApply.vue")
}, {
  path: "/workOrderDetail",
  name: "workOrderDetail",
  meta: {
    isCheck: true
  },
  component: () => import( /* webpackChunkName: "about" */"../views/workOrderBox/workOrderDetail.vue")
}, {
  path: "/register",
  name: "register",
  component: () => import( /* webpackChunkName: "about" */"../views/Register.vue")
}, {
  path: "/payRecord",
  name: "payRecord",
  meta: {
    isCheck: true
  },
  component: () => import( /* webpackChunkName: "about" */"../views/payRecord.vue")
}];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    // vue2.0  x  y  控制
    // vue3.0  left  top 控制
    return {
      left: 0,
      top: 0
    };
  }
});
router.beforeEach((to, from, next) => {
  const aiwebStore = useAiWeb();
  if (to.meta.isCheck) {
    if (localStorage.token) {
      next();
    } else {
      aiwebStore["setLoginInfo"]({
        loginFlag: true,
        type: "login"
      });
      next("/");
    }
  } else {
    next();
  }
});
export default router;