import { defineStore } from "pinia";

// main is the name of the store. It is unique across your application
// and will appear in devtools
export const useAiWeb = defineStore("aiweb", {
  state: () => ({
    userInfo: "{}",
    loginFlag: false,
    loginInfo: {
      loginFlag: false,
      type:''
    },
  }),
  // optional getters
  getters: {
    // getters receive the state as first parameter
    loginFlagG: (state) => state['loginFlag'],
  },
  // optional actions
  actions: {
    // 设置登录弹窗展示
    setLoginInfo(val) {
      console.log(val,'val')
      this["loginInfo"] = val;
    },
  },
});
