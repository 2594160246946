import axios from "axios";
import { ElLoading, ElMessage } from "element-plus";

let loadingInstance = null;
let _tempUrl = process['env']['VUE_APP_Base_URL']
let baseURL =
  process.env.NODE_ENV === "development"
    ? "/playground"
    : _tempUrl;
export const instance = axios.create({
  timeout: 60000, // 设置超时时间6s
  baseURL: baseURL,
  // headers: {
  //   'Client-Agent': '3/pc/1.0/1/' + 'pc/MacIntel' //+ navigator.platform,
  // },
});

let httpCode = {
  400: "请求参数错误",
  401: "权限不足, 请重新登录",
  403: "服务器拒绝本次访问",
  404: "请求资源未找到",
  500: "内部服务器错误",
  501: "服务器不支持该请求中使用的方法",
  502: "网关错误",
  504: "网关超时",
};

/** 添加请求拦截器 **/
instance.interceptors.request.use(
  (config) => {
    // loadingInstance = ElLoading.service({
    //   // lock: true,
    //   spinner: "el-icon-loading",
    //   text: "拼命加载中...",
    //   background: "rgba(0,0,0,0.8)",
    // });
    // console.log('baseURL', baseURL)
    if (config.method === "get") {
      // 添加时间戳参数，防止浏览器（IE）对get请求的缓存
      config.params = {
        ...config.params,
        t: new Date().getTime(),
      };
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

/** 添加响应拦截器  **/
instance.interceptors.response.use(
  (response) => {
    // loadingInstance.close();
    // 请求成功的响应数据
    if (
      response.data.code === 200 ||
      response.data.code === 1 ||
      response.data.hash ||
      response.data.format
    ) {
      return Promise.resolve(response.data);
    } else {
      if (!response.config.url.includes("auth/within/login")) {
        ElMessage({
          message: response.data.message,
          type: "error",
        });
      }
      return Promise.reject(response.data.message);
    }
  },
  (error) => {
    // loadingInstance.close();
    console.log(error);

    if (error.response) {
      // 根据请求失败的http状态码去给用户相应的提示
      let tips =
        error.response.status in httpCode
          ? httpCode[error.response.status]
          : error.response.data.message;

      ElMessage({
        message: tips,
        type: "error",
      });
      // 未登录状态下
      if (error.response.code === 12000) {
        //todo 用户没有登陆或者登陆信息已过期

        ElMessage({
          message: "请右上角登录哦~",
          type: "error",
        });
      }
      return Promise.reject(error);
    } else {
      ElMessage({
        message: "请求超时, 请刷新重试",
        type: "error",
      });
      return Promise.reject(new Error("请求超时, 请刷新重试"));
    }
  }
);

/* 统一封装get请求 */
export const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "get",
      url,
      params,
      // headers: { 'token': "" }, //store&&store.state.token
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* 统一封装post请求  */
export const post = (url, data, config = {}) => {
  return instance({
    method: "post",
    url,
    data,
    // headers: { 'token': "" },
    ...config,
  })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
