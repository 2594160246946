import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/assets/css/index.less";
import "@/assets/css/reset.css";
import hljs from "highlight.js";
import "highlight.js/styles/idea.css"; //这里有多个样式，自己可以根据需要切换
// Vue.use(hljs.vuePlugin);
const app = createApp(App);
//pinia
import { createPinia } from "pinia";
app.use(createPinia());
app.use(store);
app.use(router);
app.use(hljs.vuePlugin);
app.use(ElementPlus);
app.directive("leavehide", (el, bindings, vnode) => {
  document.onclick = function (event) {
    if (el !== event.target) {
      //判断当前触发事件的源头，是否是当前指令所在的元素
      // el.style.display = "none"; //只要是就给它设置display:none让它隐藏
    }
  };
});
if (localStorage.token) {
  instance.defaults.headers.token = localStorage.token;
  nextTick(() => {
    get("/user/api/get/key").then(res => {
      console.log(res, "res");
      localStorage.apiInfo = JSON.stringify(res.data);
    }).catch(rej => {
      console.log(rej, "rej");
    });
    post("/user/info").then(res => {
      localStorage.userInfo = JSON.stringify(res.data);
    }).catch(rej => {
      console.log(rej, "rej");
    });
  });
}
app.mount("#app");
// createApp(App).use(store).use(router).use(ElementPlus).mount('#app')