import { ElMessage } from "element-plus";
export default defineComponent({
  name: "LoginDialog",
  props: {
    type: {
      type: String,
      default: "show"
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  emit: ["toggleDialog"],
  setup(props, {
    emit
  }) {
    const getRegister = () => {
      let param = {
        ...webRegisterReq
      };
      param.phoneNumber = Encrypt(param.phoneNumber);
      param.passWord = Encrypt(param.passWord);
      param.replyPassWord = Encrypt(param.replyPassWord);
      post("/user/register", param).then(res => {
        console.log(res, "res");
        instance.defaults.headers.token = res.data.token;
        localStorage.token = res.data.token;
        localStorage.userInfo = JSON.stringify(res.data);
        toggleDialog(res.data);
        location.reload();
      }).catch(rej => {
        console.log(rej);
      });
    };
    const updatePhone = () => {
      let param = {
        ...webRegisterReq
      };
      param.phoneNumber = Encrypt(param.phoneNumber);
      param.passWord = Encrypt(param.passWord);
      param.replyPassWord = Encrypt(param.replyPassWord);
      post(`/user/change/phone?areaCode=86&code=${param.verificationCode}&phoneNumber=${param.phoneNumber}`).then(res => {
        console.log(res, "res");
        getUserInfo();
      }).catch(rej => {
        console.log(rej);
      });
    };
    const resetPwd = () => {
      if (!webRegisterReq.passWord || !webRegisterReq.replyPassWord) {
        ElMessage({
          type: "info",
          message: "密码不能为空"
        });
        return;
      }
      post(`/user/passwd/rest?code=${webRegisterReq.code}&newPasswd=${encodeURIComponent(Encrypt(webRegisterReq.passWord))}&replyNewPasswd=${encodeURIComponent(Encrypt(webRegisterReq.replyPassWord))}&email=${webRegisterReq.email}`).then(res => {
        console.log(res, "res");
        toggleDialog();
      }).catch(rej => {
        console.log(rej);
      });
    };
    const getLogin = () => {
      if (!webRegisterReq.email) {
        ElMessage({
          type: "info",
          message: "邮箱不能为空"
        });
        return;
      }
      if (!webRegisterReq.passWord) {
        ElMessage({
          type: "info",
          message: "密码不能为空"
        });
        return;
      }
      post(`/user/login?email=${webRegisterReq.email}&passWord=${encodeURIComponent(Encrypt(webRegisterReq.passWord))}`).then(res => {
        console.log(res, "res");
        instance.defaults.headers.token = res.data.token;
        localStorage.token = res.data.token;
        localStorage.userInfo = JSON.stringify(res.data);
        toggleDialog(res.data);
        location.reload();
      }).catch(rej => {
        console.log(rej);
      });
    };
    const sendEmail = () => {
      if (!webRegisterReq.email) {
        ElMessage({
          type: "info",
          message: "邮箱不能为空"
        });
        return;
      }
      post(`/user/push/email?email=${webRegisterReq.email}`).then(res => {
        console.log(res, "res");
        toggleType("reset");
      }).catch(rej => {
        console.log(rej);
      });
    };
    const getUserInfo = () => {
      post("/user/info").then(res => {
        localStorage.userInfo = JSON.stringify(res.data);
        toggleDialog(res.data);
      }).catch(rej => {
        console.log(rej, "rej");
      });
    };
    const toggleDialog = userInfo => {
      emit("toggleDialog", "", false, userInfo);
    };
    const toggleType = type => {
      emit("toggleDialog", type, true);
    };
    const webRegisterReq = reactive({
      email: "",
      passWord: "",
      phoneAreaCode: "86",
      phoneNumber: "",
      replyPassWord: "",
      userName: "",
      verificationCode: "",
      code: "",
      inviteUserId: ""
    });
    const codeLoading = ref();
    const codeLoadingText = ref("获取短信验证码");
    const getCode = () => {
      if (codeLoading.value) return;
      post(`/user/push/code?phoneNumber=${encodeURIComponent(Encrypt(webRegisterReq.phoneNumber))}&areaCode=86`).then(res => {
        console.log(res, "res");
        let num = 60;
        codeLoadingText.value = `${num}秒后重新获取`;
        codeLoading.value = setInterval(() => {
          if (num == 1) {
            clearInterval(codeLoading.value);
            codeLoadingText.value = "获取短信验证码";
            codeLoading.value = "";
          } else {
            num--;
            codeLoadingText.value = `${num < 10 ? "0" + num : num}秒后重新获取`;
          }
        }, 1000);
      }).catch(rej => {
        console.log(rej, "rej");
      });
    };
    return {
      toggleDialog,
      getRegister,
      toggleType,
      getCode,
      getLogin,
      sendEmail,
      webRegisterReq,
      codeLoadingText,
      updatePhone,
      resetPwd
    };
  }
});